import { render, staticRenderFns } from "./Swim.vue?vue&type=template&id=6d34b621&scoped=true&"
import script from "./Swim.vue?vue&type=script&lang=js&"
export * from "./Swim.vue?vue&type=script&lang=js&"
import style0 from "./Swim.vue?vue&type=style&index=0&id=6d34b621&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d34b621",
  null
  
)

export default component.exports