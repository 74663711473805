<template>
	<div class="swim">
		
        <div class="swim_main">
			<div class="swim_title">
				<span>浮游菌打开次数历史记录</span>
			</div>
			<div class="swim_data">
				<div class="table_head">
					<div class="th_div">
						<span>开关状态</span>
					</div>
					<div class="th_div">
						<span>时间</span>
					</div>
				</div>
				<div class="table_body">
					<template v-for="item in tableData">
						<div class="tb_div" :key="item.data_time">
							<div class="tbd_div">
								<span v-if="item.value=='on'">启动</span>
								<span v-else>关闭</span>
							</div>
							<div class="tbd_div">
								<span>{{getTime(item.data_time)}}</span>
							</div>
						</div>
					</template>	
					
				</div>
			</div>
        </div>

	</div>
</template>

<script>


import studio_login_api from '@/api/teaching_studio_no_login.js'
import studio_api from '@/api/teaching_studio_teaching_studio_api.js'
import until from '@/api/until.js'

export default {
	name: 'Home',
	data() {
		return {
			lastData:{},
			unit:"",
			warningList:[],
			deviceName:'',
			property_name:'',
			tableData:[]
		}
	},
	components: {

	},
	mounted() {
		document.title="浮游菌采样器"


		this.getMoreData();

	},
	methods: {
		
		getTime(time){
			return until.formatDateHour(time*1000)
		},
	
		
	
	
		//获取更多数据
		async getMoreData(){
			let app_id=this.$root.app_id;
			let property_id=this.$route.query.property_id
			let begin_time=parseInt(until.getAddDayTime(-30)/1000) ;
			let end_time=parseInt(until.getNowDayTime()/1000);
			let data=await studio_api.Teaching_studio_apiIot_device_propertyTimeDataGet({app_id,property_id,begin_time,end_time});
			if(data.errcode==0){
				if(data.other.data){
					this.tableData=data.other.data;
				}
				else{
					this.tableData=[];
				}
				
			}
		},

	
	}
}
</script>
<style scoped>
.swim{
	width: 100%;
	background: #F6F6F6;
	height: 100vh;
	display: inline-block;
    overflow: hidden;

	box-sizing: border-box;
    padding: 0 30px;


}
.swim_main{
    width: 100%;
    height: calc(100vh - 48px);
	background: #ffffff;
	margin-top: 18px;
	display: inline-block;
	border-radius: 20px;
	padding: 0 30px;
	box-sizing: border-box;
	overflow: auto;
    
}
.swim_title{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 32px;
	font-weight: bold;
	color: #333333;
	margin-top: 30px;
	
	box-sizing: border-box;
}
.swim_data{
	width: 100%;
	display: inline-block;
	border: 1px solid#DFDFDF;
	border-radius: 8px 8px 0px 0px;
	margin-top: 24px;
}
.table_head{
	width: 100%;
	height: 88px;
	background: #F6F6F6;
	opacity: 1;
	border-bottom: 1px solid #DFDFDF;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.th_div{
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 30px;
	box-sizing: border-box;
	font-size: 28px;
	color: #333333;
	font-weight: bold;
	line-height: 40px;
}
.table_body{
	width: 100%;
	display: inline-block;
}
.tb_div{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid #DFDFDF;
	height: 88px;
}
.tbd_div{
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 30px;
	box-sizing: border-box;
	font-size: 28px;
	color: #333333;
	line-height: 40px;;
}
.tb_div:last-child{
	border-bottom: 0px;
}
</style>

